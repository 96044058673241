import Marquee from "@components/Marquee";
import ImageFallback from "@components/core/Image/ImageFallback";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import {
	AddToCart,
	AddToPlaylist,
	Play,
} from "@components/interaction";
import { DeleteConfirmationModal } from "@components/modals/DeleteConfirmationModal";
import { CHART_PLACEHOLDER } from "@lib/constants";
import { ChartsContext } from "@lib/context/charts";
import { cls } from "@lib/css";
import { useChartDelete } from "@lib/hooks/useChartDelete";
import { Chart } from "@models/Chart";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { Dispatch, SetStateAction, useContext } from "react";
import {
	Actions,
	ActionsControls,
	CardActions,
	CardButton,
	CardControls,
	DeleteButton,
	ImageWrapper,
	Meta,
	Name,
	Overlay,
	Title,
	Wrapper,
} from "./ChartCard.style";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { device } from "@styles/theme";

interface Props {
	chart: Chart;
	showTitle?: boolean;
	showArtistName?: boolean;
	isDjChart?: boolean;
	dataTestId?: string;
	location?: string;
	itemControlsOpen?: number;
	setItemControlsOpen?: Dispatch<SetStateAction<number | undefined>>;
}

const IMG_WIDTH = CHART_PLACEHOLDER.image.width;
const IMG_HEIGHT = CHART_PLACEHOLDER.image.height;
const FALLBACK_URL = CHART_PLACEHOLDER.href;

const ChartCard: React.FC<Props> = ({
	chart,
	showTitle = true,
	showArtistName = true,
	isDjChart = false,
	dataTestId,
	location,
	itemControlsOpen,
	setItemControlsOpen,
}) => {
	const { t } = useTranslation("translation");
	const { isPublished } = useContext(ChartsContext);
	const { deleteChart, showConfirmationModal, setShowConfirmationModal } =
    useChartDelete();
	const isLg = useMediaQuery({ query: device.lg });
	const isItemControlsOpen = itemControlsOpen === chart.id;

	return (
		<Wrapper
			data-testid={dataTestId}
			className={cls(
				isItemControlsOpen ? "actions" : undefined,
				showArtistName ? "show-artist" : "hide-artist",
				isPublished ? "show-actions" : undefined,
			)}
		>
			<Meta>
				<Link
					href={
						isDjChart && !isPublished ?
							`/dj/charts/${chart.id}/edit` :
							`/chart/${chart.slug}/${chart.id}`
					}
					prefetch={false}
					title={chart.name}
					className="artwork"
				>
					<Overlay />
					{showTitle && (
						<Title>
							<Marquee shouldWrap={false}>
								{chart.name}
							</Marquee>
						</Title>
					)}
					{showArtistName && (
						<Name>
							<Marquee shouldWrap={false}>
								{chart.artist?.name || chart.person?.owner_name || ""}
							</Marquee>
						</Name>
					)}
					<ImageWrapper>
						<ImageFallback
							src={chart.image?.uri}
							alt={chart.name}
							width={IMG_WIDTH}
							height={IMG_HEIGHT}
							fallbackSrc={FALLBACK_URL}
							blurDataURL={FALLBACK_URL}
							placeholder="blur"
						/>
					</ImageWrapper>
				</Link>
				{isPublished ?
						(
							<CardActions>
								<Actions className={chart.price ? "price" : "no-price"}>
									<ActionsControls>
										{
											isLg && <Play chartId={chart.id} />
										}
										<AddToPlaylist chartId={chart.id} />
									</ActionsControls>

									{chart.price && (
										<AddToCart
											chart={chart}
											location={location}
										/>
									)}
								</Actions>
							</CardActions>
						) :
						(
							<>
								<DeleteButton
									onClick={() => {
										setShowConfirmationModal(true);
									}}
								>
									<SpriteIcon id="delete" data-testid="delete-icon" />
								</DeleteButton>
								<DeleteConfirmationModal
									show={showConfirmationModal}
									header={t("Charts.MyDjCharts.Modal.DeleteHeader")}
									confirmation={t("Charts.MyDjCharts.Modal.DeleteConfirm")}
									onClose={() => setShowConfirmationModal(false)}
									onDelete={() => deleteChart(chart.id)}
								/>
							</>
						)}
			</Meta>
			<CardControls>
				<Play chartId={chart.id} />
				<CardButton
					onClick={() => setItemControlsOpen?.(
						() => isItemControlsOpen ? undefined : chart.id,
					)}
				>
					<SpriteIcon id="dots" width="32" height="32" />
				</CardButton>
			</CardControls>
		</Wrapper>
	);
};

export default ChartCard;
