import styled from "styled-components";

export const Actions = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.75rem;

  padding: 8px;

  @media ${(props) => props.theme.device.lg} {
    height: 24px;
    justify-content: space-between;
    padding: 0 0 0 4px;
    flex-wrap: nowrap;
    &.no-price {
      justify-content: center;
      gap: 16px;
    }
    &.price {
      &:last-child {
        span {
          border-radius: 0;
        }

        span.price {
          padding-inline: 0;
          width: 4rem;
        }

        span.arrow {
          width: 1.5rem;
        }
      }
    }
  }
`;

export const ActionsControls = styled.div`
  display: flex;

  > :first-child {
    padding: 0.25rem;

    + * {
      padding: 0.3125rem;
    }
  }
`;

export const CardActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  width: 100%;
  height: 125px;
  top: 100%;
  transition: top 0.2s ease-in-out;
  overflow: hidden;

  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  color: ${(props) => props.theme.colors.neutrals.primary.white};

  @media ${(props) => props.theme.device.lg} {
    height: 24px;
    background: #696969;
    visibility: hidden;
    opacity: 0;
  }
`;

export const CardButton = styled.a`
  cursor: pointer;
  padding: 0 16px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
`;

export const CardControls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  border-top: 1px solid ${(props) => props.theme.colors.neutrals.primary[800]};
  z-index: 1;

  @media ${(props) => props.theme.device.lg} {
    display: none;
  }
`;

export const DeleteButton = styled.button`
  cursor: pointer;
  z-index: 1;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  position: absolute;
  width: 32px;
  height: 32px;
  right: 16px;
  top: 16px;
  border-radius: 50%;
  > svg {
    margin: 0 auto;
  }
`;

export const Title = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  height: 20px;
  letter-spacing: -0.006em;

  position: absolute;
  bottom: 35px;
  padding: 8px;

  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  max-width: 100%;

  transition: bottom 0.2s ease-in-out;
  z-index: 1;
`;

export const Name = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  height: 20px;
  position: absolute;
  bottom: 14px;
  padding: 8px;
  white-space: nowrap;
  transition: bottom 0.2s ease-in-out;
  z-index: 1;
`;

export const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, #000 0, transparent 50%);
  z-index: 1;
  bottom: 0px;
  transition: bottom 0.2s ease-in-out;
`;

// @supports is used to add fallback styles for older browsers that do not support aspect-ratio property
export const ImageWrapper = styled.div`
  @supports not(aspect-ratio) { 
    padding-top: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
  }
  img {
    width: 100%;
    object-fit: cover;
    height: auto;
    aspect-ratio: 1/1;
    @supports not(aspect-ratio) { 
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      width: auto;
      max-width: 100%; 
      height: 100%;
    }
  }
`;

export const Meta = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 262px;
  display: flex;
  flex-direction: column;

  &.actions {
    ${CardActions} {
      top: calc(100% - 120px);
      z-index: 2;
    }
  }

  &.hide-artist {
    ${Title} {
      bottom: 0px;
    }
  }

  &:not(.show-actions) {
    ${CardControls} {
      display: none;
    }
  }

  @media ${(props) => props.theme.device.lg} {
    ${CardActions} {
      top: 0;
      z-index: 2;
      top: 100%;
      visibility: visible;
      opacity: 1;
    }

    &.show-actions:hover {
      ${CardActions} {
        top: calc(100% - 24px);
        visibility: visible;
        opacity: 1;
      }

      ${Title} {
        bottom: 60px;
      }

      ${Name} {
        bottom: 40px;
      }

      ${Overlay} {
        bottom: 24px;
      }
    }

    &.hide-artist:hover {
      ${Title} {
        bottom: 24px;
      }
    }
  }
`;
